import * as React from 'react'
import { Layout } from '../components/layout'
import SEO from '../components/SEO/seov2'

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO
      pagetitle="404: Not found"
      description="This page can not be found"
      banner="/assets/office01.jpg"
    />
    <div className="relative mx-auto max-w-screen-xl p-sm-fluid">
      <h1 className="tets">NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
